// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.0-rc-10274(6a4ba1b182)-C27/05/2024-11:19:12-B27/05/2024-11:21:05' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.0-rc-10274(6a4ba1b182)-C27/05/2024-11:19:12-B27/05/2024-11:21:05",
  branch: "master",
  latestTag: "6.0-rc",
  revCount: "10274",
  shortHash: "6a4ba1b182",
  longHash: "6a4ba1b182648d1a8a665a36875334c0d247d2da",
  dateCommit: "27/05/2024-11:19:12",
  dateBuild: "27/05/2024-11:21:05",
  buildType: "Ansible",
  } ;
